import React from "react";
import HeroSectionAbout from "./aboutComponents/heroSectionAbout/HeroSectionAbout";
import Counter from "../../components/counter/Counter";
import ServicesSectionAbout from "./aboutComponents/serviceSectionAbout/ServicesSectionAbout";
import OurClints from "../../components/ourClint/OurClints";

export default function About() {
  return (
    <div>
      <HeroSectionAbout />
      <Counter />
      <ServicesSectionAbout/>
      {/* <OurClints/> */}
    </div>
  );
}
