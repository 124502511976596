import React from 'react'
import './Footer.scss'
import Logo from '../../assets/Logo.png'
import { FaFacebookF } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io5";
import { FaSquareInstagram } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { BiLogoGmail } from "react-icons/bi";

export default function Footer() {
  return (
    <div id='main-section-footer'>
      <div className="container section-footer">
<div className="top-container">
  <img src={Logo} alt="" className='logo-image'/>
  <div className="container-folow-us">
    <p className='folow-us'>folow us</p>
    <FaFacebookF className='icon-sm'/>
<IoLogoYoutube className='icon-sm'/>
<FaSquareInstagram className='icon-sm'/>
<IoLogoWhatsapp className='icon-sm'/>
  </div>
</div>
<div className="meddle-container">
<div className="contact-container">
  <h1 className='title-contact'>Contact info</h1>
  <div className="container-contact">
    <BiLogoGmail className='icon-contact'/>
  <p className='contact'>promolus.com</p>
  </div>
  <div className="container-contact">
    <IoLogoWhatsapp className='icon-contact'/>
  <p className='contact'>3456 645654 6546</p>
  </div>
</div>
<div className="container-links">
  <p className='link-footer'>الرئيسية</p>
  <p className='link-footer'>من نحن</p>
  <p className='link-footer'>مشاريعنا</p>
  <p className='link-footer'>اخبارنا</p>
  <p className='link-footer' >تواصل معنا</p>
  <p className='link-footer' >التغليف</p>
  <p className='link-footer'>انجازاتنا</p>
  <p className='link-footer'>توظيف</p>
</div>
<p className='pref-company'>هنكتب اى كلام عن الشركة كبذة كدا او اى حاجه عن شغلها وانجازاتها </p>
</div>
<div className="bottom-container">
<p className='copy-right'>جميع حقوق الشركة محفوظة لدى @ promo plus</p>
</div>
      </div>
    </div>
  )
}
