import React, { useState } from "react";
import "./ServicesSectionAbout.scss";
import { FaServicestack } from "react-icons/fa6";
import image from "../../../../assets/backgroundHeroSection.jpg";
const ServicesSectionAbout = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const tabHeaders = [
    { label: "التغليف" },
    { label: "العمالة" },
    { label: "منتجات التغليف" },
    { label: "الشحن" },
  ];

  const tabContents = [
    {
      imge: "https://images.search.yahoo.com/images/view;_ylt=AwrNZzgt6NRmqUwWAYmJzbkF;_ylu=c2VjA3NyBHNsawNpbWcEb2lkA2MwM2QwNDAxNThkYzQyZDI5MzAwYTE0YmQwNTMzODhhBGdwb3MDMgRpdANiaW5n?back=https%3A%2F%2Fimages.search.yahoo.com%2Fsearch%2Fimages%3Fp%3Dimages%26type%3DE210US91105G0%26fr%3Dmcafee%26fr2%3Dpiv-web%26tab%3Dorganic%26ri%3D2&w=2880&h=1800&imgurl=thewowstyle.com%2Fwp-content%2Fuploads%2F2015%2F01%2Fnature-images..jpg&rurl=http%3A%2F%2Fthewowstyle.com%2Fbeautiful-nature-images-free-to-download%2F&size=1588.9KB&p=images&oid=c03d040158dc42d29300a14bd053388a&fr2=piv-web&fr=mcafee&tt=Beautiful+Nature+Images+Free+To+Download+%E2%80%93+The+WoW+Style&b=0&ni=21&no=2&ts=&tab=organic&sigr=DRn4wn9LXkvI&sigb=2XiHxp..t_P1&sigi=HY4ETDZon1B7&sigt=phIY6pnrbFR1&.crumb=EbLQxmjdjUs&fr=mcafee&fr2=piv-web&type=E210US91105G0",
      icon: "fa-code",
      title: "التغليف",
      content:
        "امية العرب قصيدة تنسب لشاعر جاهلي هو الشنفرى عرفت بلامية العرب ، دون سواها من لاميات الجاهليين، ولقد تناولها مشاهير الأدب واللغة بالشرح والتحليل، ومنهم الزمخشري والمفضل الضبي والمبرد وغيرهم، كما أُعجب بها المستشرقون، فترجمت إلى الإنجليزية والفرنسية والألمانية والإيطالية واليونانية ، ولعل من أسباب ولع الأدباء والمستشرقين بها، جزالة ألفاظها، وتعبيرها الصادق عن حياة العربي وأخلاقه زمن الجاهلية ، وعن حياة الصعاليك ",
    },
    {
      imge: "https://images.search.yahoo.com/images/view;_ylt=AwrNZzgt6NRmqUwWAYmJzbkF;_ylu=c2VjA3NyBHNsawNpbWcEb2lkA2MwM2QwNDAxNThkYzQyZDI5MzAwYTE0YmQwNTMzODhhBGdwb3MDMgRpdANiaW5n?back=https%3A%2F%2Fimages.search.yahoo.com%2Fsearch%2Fimages%3Fp%3Dimages%26type%3DE210US91105G0%26fr%3Dmcafee%26fr2%3Dpiv-web%26tab%3Dorganic%26ri%3D2&w=2880&h=1800&imgurl=thewowstyle.com%2Fwp-content%2Fuploads%2F2015%2F01%2Fnature-images..jpg&rurl=http%3A%2F%2Fthewowstyle.com%2Fbeautiful-nature-images-free-to-download%2F&size=1588.9KB&p=images&oid=c03d040158dc42d29300a14bd053388a&fr2=piv-web&fr=mcafee&tt=Beautiful+Nature+Images+Free+To+Download+%E2%80%93+The+WoW+Style&b=0&ni=21&no=2&ts=&tab=organic&sigr=DRn4wn9LXkvI&sigb=2XiHxp..t_P1&sigi=HY4ETDZon1B7&sigt=phIY6pnrbFR1&.crumb=EbLQxmjdjUs&fr=mcafee&fr2=piv-web&type=E210US91105G0",
      icon: "fa-pencil-square",
      title: "العمالة",
      content:
        "امية العرب قصيدة تنسب لشاعر جاهلي هو الشنفرى عرفت بلامية العرب ، دون سواها من لاميات الجاهليين، ولقد تناولها مشاهير الأدب واللغة بالشرح والتحليل، ومنهم الزمخشري والمفضل الضبي والمبرد وغيرهم، كما أُعجب بها المستشرقون، فترجمت إلى الإنجليزية والفرنسية والألمانية والإيطالية واليونانية ، ولعل من أسباب ولع الأدباء والمستشرقين بها، جزالة ألفاظها، وتعبيرها الصادق عن حياة العربي وأخلاقه زمن الجاهلية ، وعن حياة الصعاليك",
    },
    {
      imge: "https://images.search.yahoo.com/images/view;_ylt=AwrNZzgt6NRmqUwWAYmJzbkF;_ylu=c2VjA3NyBHNsawNpbWcEb2lkA2MwM2QwNDAxNThkYzQyZDI5MzAwYTE0YmQwNTMzODhhBGdwb3MDMgRpdANiaW5n?back=https%3A%2F%2Fimages.search.yahoo.com%2Fsearch%2Fimages%3Fp%3Dimages%26type%3DE210US91105G0%26fr%3Dmcafee%26fr2%3Dpiv-web%26tab%3Dorganic%26ri%3D2&w=2880&h=1800&imgurl=thewowstyle.com%2Fwp-content%2Fuploads%2F2015%2F01%2Fnature-images..jpg&rurl=http%3A%2F%2Fthewowstyle.com%2Fbeautiful-nature-images-free-to-download%2F&size=1588.9KB&p=images&oid=c03d040158dc42d29300a14bd053388a&fr2=piv-web&fr=mcafee&tt=Beautiful+Nature+Images+Free+To+Download+%E2%80%93+The+WoW+Style&b=0&ni=21&no=2&ts=&tab=organic&sigr=DRn4wn9LXkvI&sigb=2XiHxp..t_P1&sigi=HY4ETDZon1B7&sigt=phIY6pnrbFR1&.crumb=EbLQxmjdjUs&fr=mcafee&fr2=piv-web&type=E210US91105G0",
      icon: "fa-bar-chart",
      title: "منتحات التغليف",
      content:
        "امية العرب قصيدة تنسب لشاعر جاهلي هو الشنفرى عرفت بلامية العرب ، دون سواها من لاميات الجاهليين، ولقد تناولها مشاهير الأدب واللغة بالشرح والتحليل، ومنهم الزمخشري والمفضل الضبي والمبرد وغيرهم، كما أُعجب بها المستشرقون، فترجمت إلى الإنجليزية والفرنسية والألمانية والإيطالية واليونانية ، ولعل من أسباب ولع الأدباء والمستشرقين بها، جزالة ألفاظها، وتعبيرها الصادق عن حياة العربي وأخلاقه زمن الجاهلية ، وعن حياة الصعاليك",
    },
    {
      imge: "https://images.search.yahoo.com/images/view;_ylt=AwrNZzgt6NRmqUwWAYmJzbkF;_ylu=c2VjA3NyBHNsawNpbWcEb2lkA2MwM2QwNDAxNThkYzQyZDI5MzAwYTE0YmQwNTMzODhhBGdwb3MDMgRpdANiaW5n?back=https%3A%2F%2Fimages.search.yahoo.com%2Fsearch%2Fimages%3Fp%3Dimages%26type%3DE210US91105G0%26fr%3Dmcafee%26fr2%3Dpiv-web%26tab%3Dorganic%26ri%3D2&w=2880&h=1800&imgurl=thewowstyle.com%2Fwp-content%2Fuploads%2F2015%2F01%2Fnature-images..jpg&rurl=http%3A%2F%2Fthewowstyle.com%2Fbeautiful-nature-images-free-to-download%2F&size=1588.9KB&p=images&oid=c03d040158dc42d29300a14bd053388a&fr2=piv-web&fr=mcafee&tt=Beautiful+Nature+Images+Free+To+Download+%E2%80%93+The+WoW+Style&b=0&ni=21&no=2&ts=&tab=organic&sigr=DRn4wn9LXkvI&sigb=2XiHxp..t_P1&sigi=HY4ETDZon1B7&sigt=phIY6pnrbFR1&.crumb=EbLQxmjdjUs&fr=mcafee&fr2=piv-web&type=E210US91105G0",
      icon: "fa-envelope",
      title: "الشحن",
      content:
        "امية العرب قصيدة تنسب لشاعر جاهلي هو الشنفرى عرفت بلامية العرب ، دون سواها من لاميات الجاهليين، ولقد تناولها مشاهير الأدب واللغة بالشرح والتحليل، ومنهم الزمخشري والمفضل الضبي والمبرد وغيرهم، كما أُعجب بها المستشرقون، فترجمت إلى الإنجليزية والفرنسية والألمانية والإيطالية واليونانية ، ولعل من أسباب ولع الأدباء والمستشرقين بها، جزالة ألفاظها، وتعبيرها الصادق عن حياة العربي وأخلاقه زمن الجاهلية ، وعن حياة الصعاليك",
    },
  ];

  return (
    <div id="main-section-services-about" className="container">
      <div className="tabs">
        <nav className="tab-header">
          <h5 className="title-section-servicee">ـــــــــــ خدماتنا </h5>
          {tabHeaders.map((tab, index) => (
            <button
              key={index}
              className={index === activeIndex ? "active" : "btn-style"}
              onClick={() => setActiveIndex(index)}
              role="tab"
              aria-selected={index === activeIndex}
              tabIndex={index === activeIndex ? 0 : -1}
            >
              <i className={`fa ${tab.icon}`}></i> {tab.label}
            </button>
          ))}
        </nav>
        <div
          className="tab-indicator"
          style={{ top: `calc(90px + ${activeIndex * 55}px)` }}
        ></div>
        <section className="tab-content">
          {tabContents.map((tab, index) => (
            <div key={index} className={index === activeIndex ? "active" : ""}>
              {/* <i className={`fa ${tab.icon}`}></i> */}
              <img src={image} alt="" width="300px" className="image-service" />
              {/* <div className="container-detailes-service"> */}
              {/* <FaServicestack className='icon-service'/> */}
              <h2>{tab.title}</h2>
              <p>{tab.content}</p>
            </div>
            // </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default ServicesSectionAbout;
