import React from "react";
import "./SomeProjects.scss";
import image from "../../../../assets/Rectangle123.png";
export default function SomeProjects() {
  return (
    <div id="main-section">
<div className="container-details container">
<div className="container-title">
  <h1 className="title-section">  بعضا من مشاريع <span>بروموبلس</span> </h1>
  <p className="description-short">اذا كنت تحتاج الى التطو هذا ضمن مشاريع برومو بلس اذا كنت ا ضمن مشاريع برومو بلس اذا كنت تحتاج الى التطور</p>
</div>
<div className="container-description">
<p className="description-short">اذا كنت تحتاج الى التطو هذا ضمن مشاريع برومو بلس اذا كنت كنت تحتاج الى التطو هذا ضمن مشاريع برومو بلس اذا كنت ا ضمن مشاريع برومو بلس اذا كنت تحتاج الى ا ضمن مشاريع برومو بلس اذا كنت تحتاج الى التطور</p>
<button className="btn-read-more">شاهد المزيد </button>
</div>
</div>
    <div id="section-some-projects" className="container">
      <div className="top-projects">
        <div
          className="container-project1 style-background"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="filter">
            <h1 className="title-project">هنا اسم المشروع</h1>
            <p className="description">هنا هنكتب اى كلام لحد اما نجهز الوصغ بتاع المشروع</p>
          </div>
        </div>
        <div
          className="container-project2 style-background"
          style={{ backgroundImage: `url(${image})` }}
        >
           <div className="filter">
            <h1 className="title-project">هنا اسم المشروع</h1>
            <p className="description">هنا هنكتب اى كلام لحد اما نجهز الوصغ بتاع المشروع</p>
          </div>
        </div>
      </div>
      <div className="bottom-projects">
        <div
          className="container-project3 style-background"
          style={{ backgroundImage: `url(${image})` }}
        >
           <div className="filter">
            <h1 className="title-project">هنا اسم المشروع</h1>
            <p className="description">هنا هنكتب اى كلام لحد اما نجهز الوصغ بتاع المشروع</p>
          </div>
        </div>
        <div
          className="container-project4 style-background"
          style={{ backgroundImage: `url(${image})` }}
        >
           <div className="filter">
            <h1 className="title-project">هنا اسم المشروع</h1>
            <p className="description">هنا هنكتب اى كلام لحد اما نجهز الوصغ بتاع المشروع</p>
          </div>
        </div>
        <div
          className="container-project5 style-background"
          style={{ backgroundImage: `url(${image})` }}
        >
           <div className="filter">
            <h1 className="title-project">هنا اسم المشروع</h1>
            <p className="description">هنا هنكتب اى كلام لحد اما نجهز الوصغ بتاع المشروع</p>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
