import React, { useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./OurClints.scss";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import clint1 from "../../assets/clint1.png";
import clint2 from "../../assets/clint2.png";
import clint3 from "../../assets/clint3.png";
import clint4 from "../../assets/clint4.png";
import clint5 from "../../assets/clint5.png";

export default function App() {
  const swiperRef = useRef(null);

  return (
    <>
      <div id="section-slider-clints">
        <h1 className="title-section">
          عملاء <span style={{ color: "#e32127" }}> برومو بلس </span>
        </h1>
        <p className="description"> 
          نسعي جاهدين لوصول عملائنا لاقصي عدد منالمبيعات لذلك يجب ان تكون من
          عملائنا اذا كنت تريد الوصول لاقصي عدد من المبيعات
        </p>
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          slidesPerView={3}
          centeredSlides={true}
          spaceBetween={30}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]} // Autoplay should be here
          className="mySwiper container"
        >
          <SwiperSlide className="slid-image">
            <img src={clint1} alt="" className="image-slide" />
          </SwiperSlide>
          <SwiperSlide className="slid-image">
            <img src={clint2} alt="" className="image-slide" />
          </SwiperSlide>
          <SwiperSlide className="slid-image">
            <img src={clint3} alt="" className="image-slide" />
          </SwiperSlide>
          <SwiperSlide className="slid-image">
            <img src={clint4} alt="" className="image-slide" />
          </SwiperSlide>
          <SwiperSlide className="slid-image">
            <img src={clint5} alt="" className="image-slide" />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
