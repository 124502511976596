import React from 'react'
import './HeroSectionAbout.scss'
import image from '../../../../assets/heroAbout.png'
export default function HeroSectionAbout() {
  return (
    <div id='main-section-hero-about' className='container'>
        <div className="container-image-hero">
            <img src={image} alt="" />
        </div>
        <div className="content-about-section">
        <h1 className="title">ــــــــــــــــ من نحن  </h1>
        <h1 className="heading"><span style={{color:'red'}}> برومو بلس</span> معكم  نحو المستقبل    </h1>
        <p className="description"> 
          ولكن يجب أن أشرح لك كيف نشأت كل هذه الفكرة الخاطئة المتمثلة في إدانة
          المتعة والإشادة بالألم، وسأقدم لك شرحًا كاملاً للنظام، وأشرح التعاليم
          الفعلية للمستكشف العظيم للحقيقة، وباني السعادة البشرية. لا أحد يرفض
          المتعة نفسها أو يكرهها أو يتجنبها، لأنها متعة،
        </p>
        <button className="btn">تواصل معنا</button>
      </div>
    </div>
  )
}
