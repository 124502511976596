import React from "react";
import "./Counter.scss";
import CountUp from "react-countup";
export default function Counter() {
  return (
    <div id="section-counter">
      <div className="container section-counter">

      <div className="container-counter">
     
        <h1 className="counter-number">+
          <CountUp end={100} 
          duration={5}
        enableScrollSpy= {true}
             /> 
        </h1>
        <p className="desc-counter">عدد العملاء الذين نعمل معهم</p>
      </div>
      <div className="container-counter">
        <h1 className="counter-number">+
          <CountUp end={135} 
          duration={6}
          enableScrollSpy= {true}
          /> 
        </h1>
        <p className="desc-counter">عدد المشاريع التي تم تنفيذها</p>
      </div>
      <div className="container-counter">
        <h1 className="counter-number once-style">+
          <CountUp end={125} 
          duration={6}
          enableScrollSpy= {true}
          /> 
        </h1>
        <p className="desc-counter once-style">عدد عمال شركة برومو بلس</p>
      </div>
      <div className="container-counter">
        <h1 className="counter-number">+
          <CountUp end={40} 
          duration={3}
          enableScrollSpy= {true}
          /> 
        </h1>
        <p className="desc-counter">عدد الجزائز التي حصلت عليها الشركة</p>
      </div>
      </div>
     
    </div>
  );
}
