import React from "react";
import "./Hero.scss";
import imagePromo from "../../../../assets/converted_logo_2.png";
import { useTypewriter } from "react-simple-typewriter";
import video1 from "../../../../assets/vd.mp4";
import video2 from "../../../../assets/vd2.mp4";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper/modules";
import "swiper/css/effect-fade";
// import "./SliderFilter.css";
export default function () {
  const [text] = useTypewriter({
    words: [" الغذائية", " الخطرة", " التنظيف"],
    loop: 0,
    typeSpeed: 120,
  });

  return (
    <div id="container-hero-section">
      {/* <div id="carouselExampleSlidesOnly" class="carousel slide my-style" data-bs-ride="carousel">
  <div class="carousel-inner items-style">
    <div class="carousel-item active item-style">
    <video src={video1} autoPlay muted loop style={{width:'100%' , height:'100%'}}/>
    </div>
    <div class="carousel-item">
    <video src={video2} autoPlay muted loop style={{width:'100%' , height:'100%'}}/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div> */}
      {/* <div className="container-image">

<img src={imagePromo} alt="" className="image"/>
</div>

<div className="container-content">
<h1 className="title">
          <span className="name-company">برومو بلس </span> لتعبئة وتغليف
           <span className="options"> {text} </span>
                  </h1>
        <p className="description-company">
          إن نص Lorem Ipsum هو ببساطة نص شكلي غير حقيقي لصناعة الطباعة والتنضيد.
          لقد كان نص Lorem Ipsum النص الشكلي القياسي للصناعة منذ عام 1500، عندما
          أخذ طابع غير معروف مجموعة من الحروف وقام بخلطها لصنع كتاب عينات
          الحروف. لقد نجا ليس فقط من خمسة قرون، بل وأيضًا من القفزة إلى الطباعة
          الإلكترونية
        </p>
        <div className="container-btns">
          <button className="btn-hero">تواصل معنا</button>
          <button className="btn-hero">اعرف اكثر</button>
        </div>
</div> */}

      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        effect={"fade"}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation, EffectFade]}
        className="mySwiper"
      >
        <SwiperSlide className="slide1">
          <div className="carousel-item active container-video-carousal">
            <video src={video1} autoPlay muted loop className="ve" />
           
          </div>
        </SwiperSlide>

        {/* <SwiperSlide className="slide1">
          <div className="carousel-item active container-video-carousal">
            <video src={video2} autoPlay muted loop className="ve" />
            
          </div>
        </SwiperSlide> */}
        <div className="filter-carousal-video">
              <h1 className="title">
                <span className="name-company">برومو بلس </span> لتغليف المنتجات
                <span className="name-company"> {text} </span>
                {/* <span className="options"> {text} </span> */}
              </h1>
              <p className="description-company">
                أخذ طابع غير معروف مجموعة من الحروف وقام بخلطها لصنع كتاب عينات
                الحروف. لقد نجا ليس فقط من خمسة قرون، بل وأيضًا من القفزة إلى
                الطباعة الإلكترونية
              </p>
              <div className="container-btns">
                {/* <button className="btn-hero">تواصل معنا</button> */}
                <button className="btn-hero">اعرف اكثر</button>
              </div>
              {/* <div className="container-simple">
             
              </div> */}
            </div>
        {/* <SwiperSlide  className='slide1'>  <div className="carousel-item active container-video-carousal">
            <video src={video2} autoPlay muted loop />
            <div className="filter-carousal-video">
              <div className="container-simple">
                <h1 className="aaaaaaa">
                أبعـــاد العقارية  <span className="name-company-color">
                    شريكك
                  </span>
                </h1>
              </div>
              <div className="container-simple2">
                <h1 className="aaaaaaa2">
                  في رحلة اكتشاف منزل احلامك 
                </h1>
              </div>
            </div>
          </div></SwiperSlide> */}
      </Swiper>
    </div>
  );
}
