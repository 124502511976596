import logo from './logo.svg';
import './App.scss';
import Home from './pages/home/Home';
import { Route, Routes } from "react-router-dom";
import Header from './components/header/Header';
import About from './pages/about/About';
import Projects from './pages/projects/Projects.jsx';
import News from './pages/news/News.jsx';
import Packaging from './pages/packaging/Packaging.jsx';
import Jobs from './pages/jobs/Jobs.jsx';
import Achievements from './pages/achievements/Achievements.jsx';
import Contact from './pages/contact/Contact.jsx';
function App() {
  return (
    <div className="App">
      <Header/>
      <Routes> 
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/projects" element={<Projects/>} />
        <Route path="/news" element={<News/>} />
        <Route path="/packaging" element={<Packaging/>} />
        <Route path="/achievements" element={<Achievements/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/jobs" element={<Jobs/>} />
        {/* <Route path="/about" element={<Purchase/>} /> */}
      </Routes>
     {/* <Home/> */}
    </div>
  );
}

export default App;
