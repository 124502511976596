import React from "react";
import Hero from "./homeComponents/heroSection/Hero";
import Header from "../../components/header/Header";
import AboutSection from "./homeComponents/aboutSection/AboutSection";
import Counter from "../../components/counter/Counter";
import Offers from "./homeComponents/offers/Offers";
import WhyWe from "./homeComponents/whyWe/WhyWe";
import SomeProjects from "./homeComponents/someProjects/SomeProjects";
import OurClints from "../../components/ourClint/OurClints";
import LastSection from "./homeComponents/lastSection/Lastsection";
import Footer from "../../components/footer/Footer";

export default function Home() {
  return (
    <div>
      {/* <Header /> */}
      <Hero />
      <AboutSection />
      <Counter/>
      <Offers/>
      <OurClints/>
      <WhyWe/>
      <SomeProjects/>
      <LastSection/>
      <Footer/>
    </div>
  );
}
