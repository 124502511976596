import React, { useState } from "react";
import "./WhyWe.scss";

const TabsComponent = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      label: "السرعة",
      content: (
        <div className="contai">
          <img
            src="https://images.pexels.com/photos/5088009/pexels-photo-5088009.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
          />
          <div className="container-content-slid">
            <h3>الدقة فى العمل </h3>
            <p>
              ولكن لا بد أن أشرح لكم كيف نشأت كل هذه الفكرة الخاطئة المتمثلة في
              إدانة المتعة والإشادة بالألم، وسأقدم لكم شرحًا كاملاً للنظام،
              وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة، وباني السعادة
              البشرية. لا أحد يرفض المتعة نفسها أو يكرهها أو يتجنبها، لأنها
              متعة، ولكن لأن أولئك الذين لا يعرفون كيف يسعون إلى المتعة بعقلانية
              يواجهون عواقب مؤلمة للغاية.
            </p>
            <button className="btn-read-more">اعرف المزيد</button>
          </div>
        </div>
      ),
    },
    {
      id: 1,
      label: "الرقابة",
      content: (
        <div className="contai">
          <img
            src="https://images.pexels.com/photos/5088009/pexels-photo-5088009.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
          />
          <div className="container-content-slid">
            <h3>الدقة فى العمل </h3>
            <p>
              ولكن لا بد أن أشرح لكم كيف نشأت كل هذه الفكرة الخاطئة المتمثلة في
              إدانة المتعة والإشادة بالألم، وسأقدم لكم شرحًا كاملاً للنظام،
              وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة، وباني السعادة
              البشرية. لا أحد يرفض المتعة نفسها أو يكرهها أو يتجنبها، لأنها
              متعة، ولكن لأن أولئك الذين لا يعرفون كيف يسعون إلى المتعة بعقلانية
              يواجهون عواقب مؤلمة للغاية.
            </p>
            <button className="btn-read-more">اعرف المزيد</button>
          </div>
        </div>
      ),
    },
    {
      id: 2,
      label: "الدقة",
      content: (
        <div className="contai">
          <img
            src="https://images.pexels.com/photos/5088009/pexels-photo-5088009.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
          />
          <div className="container-content-slid">
            <h3>الدقة فى العمل </h3>
            <p>
              ولكن لا بد أن أشرح لكم كيف نشأت كل هذه الفكرة الخاطئة المتمثلة في
              إدانة المتعة والإشادة بالألم، وسأقدم لكم شرحًا كاملاً للنظام،
              وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة، وباني السعادة
              البشرية. لا أحد يرفض المتعة نفسها أو يكرهها أو يتجنبها، لأنها
              متعة، ولكن لأن أولئك الذين لا يعرفون كيف يسعون إلى المتعة بعقلانية
              يواجهون عواقب مؤلمة للغاية.
            </p>
            <button className="btn-read-more">اعرف المزيد</button>
          </div>
        </div>
      ),
    },
    {
      id: 3,
      label: "متانة التغليف",
      content: (
        <div className="contai">
          <img
            src="https://images.pexels.com/photos/5088009/pexels-photo-5088009.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt=""
          />
          <div className="container-content-slid">
            <h3>الدقة فى العمل </h3>
            <p>
              ولكن لا بد أن أشرح لكم كيف نشأت كل هذه الفكرة الخاطئة المتمثلة في
              إدانة المتعة والإشادة بالألم، وسأقدم لكم شرحًا كاملاً للنظام،
              وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة، وباني السعادة
              البشرية. لا أحد يرفض المتعة نفسها أو يكرهها أو يتجنبها، لأنها
              متعة، ولكن لأن أولئك الذين لا يعرفون كيف يسعون إلى المتعة بعقلانية
              يواجهون عواقب مؤلمة للغاية.
            </p>
            <button className="btn-read-more">اعرف المزيد</button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="container-why-we">
      <div className="container-details container">
<div className="container-title">
  <h1 className="title-section"> اسباب اختيارك لشركة <span>بروموبلس</span> </h1>
  <p className="description-short">اذا كنت تحتاج الى التطو هذا ضمن مشاريع برومو بلس اذا كنت ا ضمن مشاريع برومو بلس اذا كنت تحتاج الى التطور</p>
</div>
<div className="container-description">
<p className="description-short">اذا كنت تحتاج الى التطو هذا ضمن مشاريع برومو بلس اذا كنت كنت تحتاج الى التطو هذا ضمن مشاريع برومو بلس اذا كنت ا ضمن مشاريع برومو بلس اذا كنت تحتاج الى ا ضمن مشاريع برومو بلس اذا كنت تحتاج الى التطور</p>
<button className="btn-read-more">تواصل معنا</button>
</div>
</div>
      {/* <h1 className="title-section">اسباب اختيارك <span style={{color:'#e32127'}}> برومو بلس </span></h1>
      <p className="description">
       
        برومو بلس شريكك في تغليف منتجاتك واتجه نحو الاعلى حيث الانجاز والسرعه
        اجعل برومو بلس شريكك في تغليف منتجاتك واتجه نحو الاعلى حيث الانجاز
        والسرعه والنجاح
      </p> */}
      <div className="tabs-container">
        <div className="tabs">
          {tabs.map((tab) => (
            <p
              key={tab.id}
              className={activeTab === tab.id ? "activee" : ""}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </p>
          ))}
        </div>

        <div className="contents">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`box ${activeTab === tab.id ? "show" : "hide"}`}
            >
              {tab.content}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TabsComponent;
