import React from "react";
import "./Offers.scss";
import offerMain from "../../../../assets/offerMain.png";
import offer2 from "../../../../assets/offer2.png";
export default function Offers() {
  return (
<div id="main-section-offers">
<div className="container-details container">
<div className="container-title">
  <h1 className="title-section">  بعضا من مشاريع <span>بروموبلس</span> </h1>
  <p className="description-short">اذا كنت تحتاج الى التطو هذا ضمن مشاريع برومو بلس اذا كنت ا ضمن مشاريع برومو بلس اذا كنت تحتاج الى التطور</p>
</div>
<div className="container-description">
<p className="description-short">اذا كنت تحتاج الى التطو هذا ضمن مشاريع برومو بلس اذا كنت كنت تحتاج الى التطو هذا ضمن مشاريع برومو بلس اذا كنت ا ضمن مشاريع برومو بلس اذا كنت تحتاج الى ا ضمن مشاريع برومو بلس اذا كنت تحتاج الى التطور</p>
<button className="btn-read-more">تواصل معنا </button>
</div>
</div>
    <div id="container-offers-section" className="container">
      <div className="offer-main">
        <img src={offerMain} alt="" className="image-offer-main" />
        <div className="container-content-offer-main">
          <h1 className="title">
            {" "}
            <span>عرض السنة </span> الجديدة علي التغليفات حاجه تانيه
          </h1>
          <p className="desc-offer">
            علي التغليفات حاجه علي التغليفات حاجه علي التغليفات حاجه علي
            التغليفات حاجه علي التغليفات حاجه علي التغليفات حاجهعلي التغليفات
            حاجه علي التغليفات حاجه
          </p>
        </div>
      </div>
      <div className="offer-secondary">
<img src={offer2} alt="" className="image-offer-tow"/>
<h1 className="title-second-offer">عرض <span style={{color:'red'}}> 2000 </span>عبوة </h1>
<p className="desc-offer-secondary">
            علي التغليفات حاجه علي التغليفات حاجه علي التغليفات حاجه علي
            التغليفات حاجه علي التغليفات حاجه علي التغليفات حاجهعلي التغليفات
            التغليفات حاجه علي التغليفات حاجه علي التغليفات حاجهعلي التغليفات
            حاجه علي التغليفات حاجه
          </p>
 </div>
      <div className="offer-secondary-tow">
        <h1 className="title-second-offer">عرض <span style={{color:'red'}}> 2000 </span>عبوة </h1>
<p className="desc-offer-secondary">
            علي التغليفات حاجه علي التغليفات حاجه علي التغليفات حاجه علي
            التغليفات حاجه علي التغليفات حاجه علي التغليفات حاجهعلي التغليفات
            التغليفات حاجه علي التغليفات حاجه علي التغليفات حاجهعلي التغليفات
            حاجه علي التغليفات حاجه
          </p>
<img src={offer2} alt="" className="image-offer-tow"/>
 </div>
 {/* <div className="offer-secondary">

 </div> */}
    </div>
    </div>
  );
}
