import React, { useState, useEffect } from "react";
import Logo from "../../assets/Logo.png";
import "./Header.scss";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
export default function Header() {
  return (
    <div className="contaihner" id="container-header">
      <div className="container container-header-2">
        <motion.img
          // initial={{ opacity: 0, scale: 0.1 }}
          animate={{
            scale: [1, 2, 2, 1, 1],
            rotate: [0, 0, 270, 270, 0],
            borderRadius: ["20%", "20%", "50%", "50%", "0%"],
          }}
          transition={{ ease: "easeOut",duration: 2 }}
          src={Logo}
          alt="logo promo plus company"
          className="Logo"
        />

        <motion.div
          initial={{ y: 200 }}
          animate={{ y: 0 }}
          transition={{ ease: "easeOut",duration: 2 }}
          className="container-links-btn"
        >
          <div className="container-links">
            <NavLink to={"/"} className="link">
              الرئيسية
            </NavLink>
            <NavLink to={"/about"} className="link">
              من نحن
            </NavLink>
            <NavLink to={"/projects"} className="link">
              مشاريعنا
            </NavLink>
            <NavLink to={"/news"} className="link">
              اخبارنا
            </NavLink>
            <NavLink to={"/contact"} className="link">
              تواصل معنا
            </NavLink>
            <NavLink to={"/packaging"} className="link">
              التغليف
            </NavLink>
            <NavLink to={"/achievements"} className="link">
              انجازاتنا
            </NavLink>
            <NavLink to={"/jobs"} className="link">
              توظيف
            </NavLink>
          </div>
          <motion.button
            initial={{ y: -150 }}
            animate={{ y: 0 }}
            transition={{ ease: "easeOut",duration: 2 ,delay: 1}}
            className="btn-header"
          >
            تواصل معنا
          </motion.button>
        </motion.div>
      </div>
    </div>
  );
}
