import React from 'react'
import './LastSection.scss'
import image from '../../../../assets/lastsection.jpg'

export default function LastSection() {
  return (
   <div className='container' id='section-last'>
    <div className="card-section" style={{backgroundImage: `url(${image})`}}>
<h1 className='title-last-section'>شركة برومو بلس لتغليف المنتجات</h1>
<p className='description'>يتم استخدام الفئات .swiper-button-prevand .swiper-button-nextلتصميم أزرار التنقل.
تُستخدم الخاصيتان leftو rightلوضع الأزرار خارج شريط التمرير. يمكنك ضبط هذه القيم لتحريك الأزرار بعيدًا عن شريط التمرير.
ويضمن ذلك z-indexظهور الأزرار أعلى محتوى شريط التمرير.</p>
<button className='contact'>تواصل معنا</button>
    </div>
   </div>
  )
}
