import React from "react";
import "./AboutSection.scss";
import image from "../../../../assets/converted_logo_2.png";
import image2 from "../../../../assets/abouy.png";
import { motion } from "framer-motion";
export default function AboutSection() {
  return (
    <div id="container-about-section" className="container" >
      <div className="content">
        <h1 className="title">ــــــــــــــــ حول الشركة </h1>
        <h1 className="heading"><span style={{color:'red'}}> خيارك الافضل </span> شركة برومو بلس    </h1>
        <p className="desc"> 
          ولكن يجب أن أشرح لك كيف نشأت كل هذه الفكرة الخاطئة المتمثلة في إدانة
          المتعة والإشادة بالألم، وسأقدم لك شرحًا كاملاً للنظام، وأشرح التعاليم
          الفعلية للمستكشف العظيم للحقيقة، وباني السعادة البشرية. لا أحد يرفض
          المتعة نفسها أو يكرهها أو يتجنبها، لأنها متعة،
        </p>
        <button className="btn">اعرف اكثر</button>
      </div>
      <motion.div
          initial={{ y: -1000 }}
          animate={{ y: 0 }}
          transition={{ ease: "easeOut",duration: 2 }}
      className="image-container">
        <img src={image} alt="image factory promo plus" className="image" />
      </motion.div>
    </div>
  );
}
